import * as React from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import Icon from "../Icon";
import { withAuth, WithAuth } from "../../auth/withAuth";

interface Props extends WithAuth {}

interface State {
  anchorEl: any;
}

class HeaderMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? "header-menu" : null}
          aria-haspopup="true"
          onClick={this.toggleMenu}
        >
          <Icon icon="more" />
        </IconButton>
        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.toggleMenu}
        >
          <MenuItem onClick={this.contactUs}>Contact Us</MenuItem>
          <MenuItem onClick={this.logout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }

  private toggleMenu = (event: React.MouseEvent) => {
    if (this.state.anchorEl) {
      this.setState({ anchorEl: null });
    } else {
      this.setState({
        anchorEl: event.currentTarget
      });
    }
  };

  private contactUs = (event: React.MouseEvent) => {
    this.toggleMenu(event);
    location.href = "https://www.sargon.com/contact/";
  };

  private logout = (event: React.MouseEvent) => {
    this.toggleMenu(event);
    this.props.auth
      .logout("/sign-in", {
        message: "You are now logged out.",
        error: false
      })
      .then(() => console.log("Logged out"))
      .catch(() => console.log("Error logging out"));
  };
}

export default withAuth(HeaderMenu);
