import React from "react";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Toast from "./components/common/Toast";
import { SecurityProvider } from "./components/auth/SecurityContext";
import { DatasetProvider } from "./components/auth/DatasetContext";
import { ApolloProvider } from "react-apollo";
import client from "./utils/graphql";
import "./styles/icons.css";
import HttpAuthHandler from "./components/auth/HttpAuthHandler";
import UserProvider from "./components/auth/UserContext";
import "typeface-muli";
import "typeface-saira";
import { Helmet } from "react-helmet";
import { DelayLoading } from "./components/common/PageProgressBar";
import AutoLogout from "./components/auth/AutoLogout";
import theme from "./Theme";

function withRoot(Component: any) {
  interface Props {
    muiPageContext: any;
  }

  class WithRoot extends React.Component<Props> {
    // private muiPageContext: any = null;

    constructor(props: Props) {
      super(props);
      // this.muiPageContext = this.props.muiPageContext || getPageContext();
    }

    render() {
      // MuiThemeProvider makes the theme available down the React tree thanks to React context.
      return (
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>Impact | Certane</title>
          </Helmet>
          <DelayLoading />
          <CssBaseline />
          <Toast />
          <SecurityProvider>
            <UserProvider>
              <DatasetProvider>
                <HttpAuthHandler />
                <AutoLogout />
                <ApolloProvider client={client}>
                  <Component {...this.props} />
                </ApolloProvider>
              </DatasetProvider>
            </UserProvider>
          </SecurityProvider>
        </MuiThemeProvider>
      );
    }
  }

  return WithRoot;
}

export default withRoot;
