import React, { Component } from "react";
import {
  Typography,
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(2),
      marginTop: theme.spacing(4)
    },
    copyright: {
      color: theme.palette.grey[500],
      margin: "0 10px"
    },
    links: {
      color: theme.palette.grey[500],
      "& a": {
        color: theme.palette.grey[500],
        textDecoration: "none",
        margin: "0 10px"
      }
    }
  });

class Footer extends Component<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.footer}>
        <Typography className={classes.copyright}>
          Copyright &copy; Certane. All Rights Reserved. &nbsp;
        </Typography>
        <Typography className={classes.links}>
          <a href="https://www.sargon.com/terms/" target="_blank">
            Terms
          </a>
          |
          <a href="https://www.sargon.com/privacy/ " target="_blank">
            Privacy
          </a>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
